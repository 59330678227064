import { useTranslation } from 'react-i18next';
import { Typography, Link } from '@mui/material';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ProductSize } from '@kotipizzagroup/kotipizza-pricing-api-client';
import { CheckCircle, Error } from '@mui/icons-material';
import { useAllS4DProducts } from '../../../apis/products-api';
import { useProductRestoMapping } from '../../../apis/restoIntegration-service';
import { UpcomingProductDto, UpcomingProductSizeDto } from '@kotipizzagroup/kotipizza-products-api-client';
import { formatDecimal } from '../../../shared/utils/formatters';
import { s4dAdminBase } from '../../../shared/utils/configuration';
import { groupBy } from 'lodash';
import dayjs from 'dayjs';

export type RecipeDetails = {
  ingredientName: string;
  ingredientQuantity: number;
  isActive: boolean;
  ingredientId: number;
  dosings: RecipeDosings[];
};

export type RecipeDosings = {
  dosingInGrams: number;
  sizeId: number;
};

type UpcomingProductSizeWithExtraDataDto = UpcomingProductSizeDto & {
  price: number;
  pictureCount: number;
};

type UpcomingProductWithExtraDataDto = Omit<UpcomingProductDto, 'sizes'> & {
  sizes?: UpcomingProductSizeWithExtraDataDto[] | null;
};

export const UpcomingProductsCard: React.FC<{
  product: UpcomingProductWithExtraDataDto;
  prices: ProductSize[];
}> = ({ product, prices }) => {
  const { t } = useTranslation();

  const { data: s4dProducts } = useAllS4DProducts();
  const { data: productRestoMappings } = useProductRestoMapping(product.productId ?? 0, true);

  const s4dProduct = s4dProducts?.find((p) => p.productId == product.productId);
  const hasS4DProduct = Boolean(s4dProduct);

  const restoCodesBySize = product?.sizes?.map((size) => {
    const restoIdStatus =
      (productRestoMappings?.find((x) => x.sizeId == size.sizeId) || {}).restoArticleId ?? 'Puuttuu' ?? '';
    return { sizeId: size.sizeId, name: size.name, restoIdStatus: restoIdStatus };
  });

  const missingCalories = findMissingCalories(product);
  const missingCarbon = findMissingCarbon(product);

  const ingredientList =
    product.sizes?.flatMap((size) => {
      return (
        size.ingredients?.flatMap((ingredient) => {
          return { ...ingredient, sizeId: size.sizeId };
        }) ?? []
      );
    }) ?? [];

  const groupedRecipe = groupBy(ingredientList, (ingredient) => {
    return ingredient?.ingredientId ?? 0;
  });

  const recipeDetails = Object.keys(groupedRecipe).map((ingredientId: string) => {
    const ingredients = groupedRecipe[ingredientId];
    const ingredient = ingredients[0];
    return {
      ingredientName: ingredient.ingredientName,
      ingredientQuantity: ingredient.quantity,
      isActive: ingredient.isActive,
      ingredientId: ingredient.ingredientId,
      dosings: ingredients.map((ingredient) => {
        return { sizeId: ingredient.sizeId, dosingInGrams: ingredient.dosing };
      }),
    };
  }) as RecipeDetails[];

  const readyForPublish = useMemo(
    () => validateProductFields(product, missingCalories, missingCarbon, prices, recipeDetails),
    [product, prices, recipeDetails, missingCalories, missingCarbon]
  );

  return (
    <Container>
      <TitleRow>
        <InfoLabel>
          <Typography variant="h2">{product.name}</Typography>
          {readyForPublish ? (
            <Status isReady={true}>{t('products.upcoming.readyToPublish')}</Status>
          ) : (
            <Status isReady={false}>{t('products.upcoming.cantBePublished')}</Status>
          )}
        </InfoLabel>
        <StyledLink href={`/products/${product.productId}`}>{t('products.upcoming.edit')}</StyledLink>
      </TitleRow>
      <InformationRow>
        <InfoLabel>{t('products.upcoming.releaseDate')}</InfoLabel>
        <p>{dayjs(product.releaseDateTime).format('DD.MM.YYYY')}</p>
      </InformationRow>
      <InformationRow>
        <InfoLabel>{t('products.upcoming.category')}</InfoLabel>
        {product.categoryName && product.categoryName.length > 0 ? (
          <p>{product.categoryName}</p>
        ) : (
          <MissingText>{t('products.upcoming.informationMissing')}</MissingText>
        )}
      </InformationRow>
      <InformationRow>
        <InfoLabel>{t('products.upcoming.productPictures')}</InfoLabel>
        <InfoDescription>
          {product.sizes?.map((size) => (
            <ValueRow key={size.sizeId}>
              <Value>{size.name} </Value>
              {size.pictureCount === 4 ? (
                <Value isRight={true}>
                  {size.pictureCount}
                  {t('products.upcoming.picturesAdded')}
                </Value>
              ) : (
                <Value isRight={true}>
                  <MissingText>
                    {size.pictureCount}
                    {t('products.upcoming.picturesAdded')}
                  </MissingText>
                </Value>
              )}
            </ValueRow>
          ))}
        </InfoDescription>
      </InformationRow>
      <InformationRow>
        <InfoLabel>{t('products.upcoming.description')}</InfoLabel>
        {product.description && product.description.length > 0 ? (
          <InfoDescription>{product.description}</InfoDescription>
        ) : (
          <MissingText>{t('products.upcoming.informationMissing')}</MissingText>
        )}
      </InformationRow>
      <InformationRow>
        <InfoLabel>{t('products.upcoming.sizesAndPrices')}</InfoLabel>
        <InfoDescription>
          {product.sizes?.map((size) => (
            <ValueRow key={size.sizeId}>
              <Value>{size.name}</Value>
              <Value isRight={true}>
                {size.price > 0 ? (
                  formatDecimal(size.price, ' €')
                ) : (
                  <MissingText>{t('products.upcoming.informationMissing')}</MissingText>
                )}
              </Value>
            </ValueRow>
          ))}
        </InfoDescription>
      </InformationRow>
      <InformationRow>
        <InfoLabel>{t('products.upcoming.nutrition')}</InfoLabel>
        <InfoDescription>
          {product.sizes?.map((size) => (
            <ValueRow key={size.sizeId}>
              <Value>{size.name} </Value>
              <Value isRight={true}>{formatDecimal(countKCalories(size), ' kcal')}</Value>
            </ValueRow>
          ))}
        </InfoDescription>
      </InformationRow>
      {missingCalories && missingCalories.length > 0 && (
        <InformationRow>
          <InfoLabel>{t('products.upcoming.caloriesMissing')}</InfoLabel>
          <InfoDescription>
            <MissingText>
              {missingCalories?.map((ingredient) => (
                <div>{ingredient}</div>
              ))}
            </MissingText>
          </InfoDescription>
        </InformationRow>
      )}
      <InformationRow>
        <InfoLabel>{t('products.upcoming.carbonFootprint')}</InfoLabel>
        <InfoDescription>
          {product.sizes?.map((size) => (
            <ValueRow key={size.sizeId}>
              <Value>{size.name} </Value>
              <Value isRight={true}> {formatDecimal(countCarbonFootprint(size), ' kg')}</Value>
            </ValueRow>
          ))}
        </InfoDescription>
      </InformationRow>
      {missingCarbon && missingCarbon.length > 0 && (
        <InformationRow>
          <InfoLabel>{t('products.upcoming.carbonFootprintMissing')}</InfoLabel>
          <InfoDescription>
            <MissingText>
              {missingCarbon?.map((ingredient) => (
                <div key={ingredient}>{ingredient}</div>
              ))}
            </MissingText>
          </InfoDescription>
        </InformationRow>
      )}
      <SubTitleRow>
        <Typography variant="h3">{t('products.upcoming.recipe')}</Typography>
      </SubTitleRow>
      <RecipeContainer>
        {recipeDetails.map((ingredient) => (
          <RecipeRow key={ingredient.ingredientId}>
            <InfoLabel>
              <StyledLink href={`/ingredients/${ingredient.ingredientId}`}>
                {ingredient.isActive ? (
                  ingredient.ingredientName
                ) : (
                  <MissingText>
                    {ingredient.ingredientName} {t('products.upcoming.inactive')}
                  </MissingText>
                )}
              </StyledLink>
            </InfoLabel>
            <Value>
              <p>x {ingredient.ingredientQuantity}</p>
            </Value>
            {ingredient.dosings.map((sizeData: RecipeDosings) => (
              <React.Fragment key={sizeData.sizeId}>
                {sizeData.dosingInGrams > 0 ? (
                  <Value isRight={true}>
                    <p>{sizeData.dosingInGrams}g</p>{' '}
                  </Value>
                ) : (
                  <Value isCentered={true}>
                    <MissingText> {t('products.upcoming.informationMissing')}</MissingText>
                  </Value>
                )}
              </React.Fragment>
            ))}
          </RecipeRow>
        ))}
      </RecipeContainer>
      <SubTitleRow>
        <Typography variant="h3">Linkitykset</Typography>
      </SubTitleRow>

      {restoCodesBySize?.map((i) => (
        <InformationRow key={i.sizeId}>
          <Value isCentered={true}>
            {i.restoIdStatus === 'Puuttuu' ? <Error color="error" /> : <CheckCircle color="success" />}
            Resto-koodi ({i.name}): {i.restoIdStatus}
          </Value>
        </InformationRow>
      ))}
      <InformationRow>
        {hasS4DProduct ? (
          <>
            <Value isCentered={true}>
              <CheckCircle color="success" />
              S4D ID: {s4dProduct?.s4dId}
            </Value>
            <Link href={`${s4dAdminBase()}/Content/Secure/Menu/Products/Details.aspx?product=${s4dProduct?.s4dId}`}>
              S4D Admin
            </Link>
          </>
        ) : (
          <Value isCentered={true}>
            <Error color="error" /> S4D Tuotetta ei löydy
          </Value>
        )}
      </InformationRow>

      <SubTitleRow>
        <Typography variant="h3">{t('products.upcoming.extraInformation')}</Typography>
      </SubTitleRow>
      <br />
    </Container>
  );
};

const validateProductFields = (
  product: UpcomingProductDto,
  missingCalories: (string | null | undefined)[],
  missingCarbon: (string | null | undefined)[],
  prices: ProductSize[],
  recipeDetails: RecipeDetails[]
) => {
  if (!product.categoryName || !product.description || !product.name) {
    return false;
  }
  if ((missingCalories && missingCalories.length > 0) || (missingCarbon && missingCarbon.length > 0)) {
    return false;
  }
  product.sizes?.forEach((size) => {
    const price = getProductPrice(product.productId, size.sizeId, prices);
    if (!size.hasDesktopImage || !size.hasDesktopMenuImage || !size.hasMobileImage || !size.hasMobileMenuImage) {
      return false;
    }
    if (price === 0) {
      return false;
    }
  });
  recipeDetails.forEach((ingredient) => {
    if (ingredient.isActive === false) {
      return false;
    }
    ingredient.dosings.forEach((dosing) => {
      if (dosing.dosingInGrams === 0) {
        return false;
      }
    });
  });
  return true;
};

const countKCalories = (size: UpcomingProductSizeDto) => {
  if (size) {
    const caloriesTotal = size.ingredients
      ?.map((ingredient) => {
        return {
          ...ingredient,
          calories: (ingredient?.energy ? ingredient?.energy / 100 : 0) * (ingredient?.dosing ? ingredient.dosing : 0),
        };
      })
      .reduce((acc, curr) => acc + curr.calories, 0);
    return Math.ceil(caloriesTotal ? caloriesTotal * 100 : 0) / 100;
  }
  return 0;
};

const countCarbonFootprint = (size: UpcomingProductSizeDto) => {
  if (size) {
    const carbonFootprintTotal = size.ingredients
      ?.map((ingredient) => {
        return {
          ...ingredient,
          carbonFootprint:
            (ingredient?.carbonFootprint ? ingredient?.carbonFootprint / 10 : 0) *
            (ingredient?.dosing ? ingredient.dosing : 0),
        };
      })
      .reduce((acc, curr) => acc + curr.carbonFootprint, 0);
    return Math.ceil(carbonFootprintTotal ? carbonFootprintTotal : 0 * 100) / 100;
  }
  return 0;
};

const findMissingCalories = (product: UpcomingProductDto) => {
  const listOfMissingIngredients =
    product.sizes &&
    product.sizes
      .flatMap((size) => {
        const missingIngredientPerSize =
          size.ingredients &&
          size.ingredients.map((ingredient) => {
            if (ingredient.energy === null || ingredient.energy === undefined || ingredient.energy <= 0) {
              return ingredient.ingredientName + ' [' + ingredient.ingredientId + '] ';
            }
          });
        return missingIngredientPerSize;
      })
      .filter((item) => item !== undefined);
  const uniqueIngredients = [...new Set(listOfMissingIngredients)];
  return uniqueIngredients ?? [];
};

const findMissingCarbon = (product: UpcomingProductDto) => {
  const listOfMissingIngredients =
    product.sizes &&
    product.sizes
      .flatMap((size) => {
        const missingIngredientPerSize =
          size.ingredients &&
          size.ingredients.map((ingredient) => {
            if (
              ingredient.carbonFootprint === null ||
              ingredient.carbonFootprint === undefined ||
              ingredient.carbonFootprint <= 0
            ) {
              return ingredient.ingredientName + ' [' + ingredient.ingredientId + ']';
            }
          });
        return missingIngredientPerSize;
      })
      .filter((item) => item !== undefined);
  const uniqueIngredients = [...new Set(listOfMissingIngredients)];

  return uniqueIngredients ?? [];
};

const getProductPrice = (productId?: number, sizeId?: number, prices?: ProductSize[]) => {
  return prices?.find((price) => price.productID === productId && price.productSizeID === sizeId)?.calculatedPrice ?? 0;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  background-color: white;
  padding: 16px;
  margin: 8px;
`;

const RecipeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.div<{ isReady: boolean }>`
  background: red;
  border-radius: 15px;
  padding: 7px;
  margin: 5px 0px;
  color: white;
  text-align: center;

  ${({ isReady }) =>
    isReady &&
    `
    background: green;
  `}
`;

const InfoLabel = styled.div`
  width: 150px;
  min-width: 150px;
  align-content: center;
`;

const InfoDescription = styled.div`
  align-content: center;
`;

const InformationRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: solid lightgray 1px;
  padding: 8px 0px 8px;
  align-items: baseline;
`;

const ValueRow = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

const MissingText = styled.div`
  color: red;
`;

const Value = styled.div<{ isRight?: boolean; isCentered?: boolean }>`
  min-width: 80px;

  ${({ isRight }) =>
    isRight &&
    `
    text-align: right;
  `}
  ${({ isCentered }) =>
    isCentered &&
    `
    display: flex;
    align-items: center;
    justify-content: right;
  `}
`;

const RecipeRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: solid lightgray 1px;
  padding: 8px 0px 8px;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SubTitleRow = styled.div`
  margin: 20px 8px 0px;
`;

const StyledLink = styled.a`
  font-size: 15px;
  color: green;
`;
